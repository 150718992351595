/* You can add global styles to this file, and also import other style files */
:root {
    --plyr-color-main: #6855e0;
  }

:root {
  scroll-behavior: smooth;
}

  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .content {
    min-height: calc(100vh - 100px); 
    padding-bottom: 100px;
  }
  
  app-footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100px; 
  }


  .button {
    width: 140px;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #fff;
    background-color: #6855e0;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    }
  
  .button:hover {
    background-color: #fff;
    box-shadow: 0px 15px 20px rgba(165, 46, 229, 0.4);
    color: #000;
    transform: translateY(-2px);
  }

  .a-link {
    position: relative;
    text-decoration: none;
    color: rgb(104, 85, 224);
    font-weight: 600;
    transition: color 0.4s;
    padding: 5px 0;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
